import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { theme } from "../../../utils/global-styles/theme";

const DisplayCard = ({ title }) => {
  const Card = styled.div`
      height: 280px;
      width: 280px;
      margin: 10px 20px 10px 0px;
      padding: 0 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        width: calc(50% - 7px);
        margin: 10px 0px 0px 0px;
        height: 168px;
        padding: 0 20px;
      }
    `,
    Title = styled.p`
      line-height: 42px;
      font-size: 35px;
      font-weight: bold;
      letter-spacing: -0.91px;
      font-family: ${theme.fontFaces.headers};
      text-align: center;
      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: -0.52px;
        line-height: 24px;
      }
    `;
  return (
    <Card className="secondary-bg white">
      <Title>{title}</Title>
    </Card>
  );
};

DisplayCard.propTypes = {
  title: PropTypes.string,
};

export default DisplayCard;
