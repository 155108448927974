import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import { theme } from "../../utils/global-styles/theme";
import RightGoToArrow from "../CourseCard/Icons/RightGoToArrow";

const Link4 = styled(Link)`
    font-size: 17px;
    letter-spacing: -0.37px;
    line-height: 20px;
    font-weight: bold;
    text-transform: uppercase;

    @media only screen and (max-width: ${theme.breakpoints.mobile}) {
      font-size: 13px;
      letter-spacing: -0.28px;
      line-height: 16px;
    }
  `,
  ExternalLink4 = styled.a`
    font-size: 17px;
    letter-spacing: -0.37px;
    line-height: 20px;
    font-weight: bold;
    text-transform: uppercase;

    @media only screen and (max-width: ${theme.breakpoints.mobile}) {
      font-size: 13px;
      letter-spacing: -0.28px;
      line-height: 16px;
    }
  `,
  Underline = styled.span`
    margin-right: 8px;
    transition: margin 700ms;

    &.hover {
      margin-right: 18px;
      border-bottom: 3px solid;
    }
  `;

/*
 * See: https://projects.invisionapp.com/share/PVT8FPMGTU5#/screens/376610755
 *
 * Link 4 (With Arrow)
 */
export const Link4Arrow = ({
  className,
  to,
  children,
  fontStyles,
  svgWidth,
  svgHeight,
  isExternalLink,
}) => {
  const [hover, setHover] = useState(false),
    hoverOn = () => setHover(true),
    hoverOff = () => setHover(false);

  return isExternalLink ? (
    <ExternalLink4
      css={fontStyles}
      href={to}
      onMouseEnter={hoverOn}
      onMouseLeave={hoverOff}
      target="_blank"
    >
      <Underline className={hover ? "secondary-border hover" : ""}>
        {children}
      </Underline>
      <RightGoToArrow
        width={svgWidth || "20px"}
        height={svgHeight || "11.43px"}
        fillColor={hover ? "secondary-fill" : "primary-fill"}
      />
    </ExternalLink4>
  ) : (
    <Link4
      css={fontStyles}
      className={`main`}
      to={`${to}/`}
      onMouseEnter={hoverOn}
      onMouseLeave={hoverOff}
    >
      <Underline className={hover ? "secondary-border hover" : ""}>
        {children}
      </Underline>
      <RightGoToArrow
        width={svgWidth || "20px"}
        height={svgHeight || "11.43px"}
        fillColor={hover ? "secondary-fill" : "primary-fill"}
      />
    </Link4>
  );
};

Link4Arrow.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string,
  children: PropTypes.node,
  fontStyles: PropTypes.string,
  svgHeight: PropTypes.string,
  svgWidth: PropTypes.string,
  isExternalLink: PropTypes.bool,
};

export default Link4Arrow;
