import React from "react";
import styled from "@emotion/styled";
import get from "lodash/get";
import { Link, graphql, navigate } from "gatsby";

import { theme } from "../../../utils/global-styles/theme";

import Globe from "../../Fellowship/child-components/FellowshipAroundTheGlobe/Globe";

const HeroContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    max-width: 880px;

    padding-left: 160px;
    @media only screen and (max-width: ${theme.breakpoints.helper1350}) {
      padding-left: 120px;
    }
  `,
  Heading = styled.h1`
    z-index: 0;
  `,
  Subheading = styled.h4`
    z-index: 0;
  `,
  CtaContainer = styled.div`
    z-index: 2;
    height: auto;
    margin-top: auto;
    margin-bottom: 0px;

    @media only screen and (max-width: ${theme.breakpoints.helper800}) {
      display: none;
    }
  `,
  CtaText = styled.h2`
    font-size: 35px;
    font-weight: bold;
    letter-spacing: -0.91px;
    line-height: 35px;
    margin-bottom: 22px;
  `,
  CtaButton = styled.a`
    z-index: 2;
    height: 36px;
    width: 166px;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.4px;
    line-height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid;
  `;

const Map = ({ className, heading, subheading, cta, carouselItems }) => {
  const regions = carouselItems.map((region, index) => {
    return {
      label: get(region, "linkText", "Region"),
      onClick: () => navigate(`${region.url}/`),
    };
  });

  return (
    <Globe
      regions={regions}
      className={`${className || ""} secondary-bg`}
      css={{ paddingTop: "120px", paddingBottom: "120px" }}
    >
      <HeroContainer>
        <Heading>{heading || "Regional"}</Heading>
        <Subheading>
          {subheading ||
            "We believe in a world that is locally rooted and globally connected."}
        </Subheading>
        <CtaContainer>
          <CtaText>
            {get(
              carouselItems,
              "[0].title",
              "Want to start an Acumen Fellowship in your country?"
            )}
          </CtaText>
          <CtaButton
            className="white white-button"
            href={"mailto:fellowsinfo@acumenacademy.org"}
          >
            {get(cta, "linkText", "Get in Touch")}
          </CtaButton>
        </CtaContainer>
      </HeroContainer>
    </Globe>
  );
};

export const query = graphql`
  fragment PartnersMap on ContentfulPageLayout {
    contentModules {
      ... on ContentfulCarouselContent {
        title
        heading
        subheading
        cta {
          linkText
          url
        }
        carouselItems {
          ... on ContentfulLink {
            linkText
            linkTextAlt
            url
            startDate
            endDate
          }
        }
      }
    }
  }
`;

export default Map;
