import React from "react";
import styled from "@emotion/styled";
import get from "lodash/get";
import { Link } from "gatsby";

const Container = styled.div`
    margin-left: 14px;
    margin-right: 14px;
    padding-top: 58px;
  `,
  Heading = styled.h1`
    margin-bottom: 13px;
  `,
  Subheading = styled.h4`
    margin-bottom: 14px;
  `,
  CtaContainer = styled.div`
    z-index: 2;
    height: auto;
    margin-top: auto;
    margin-bottom: 0px;
  `,
  CtaText = styled.p`
    display: inline;
  `,
  CtaButton = styled.a`
    display: inline;
  `,
  RegionList = styled.ul`
    margin-top: 25px;
    columns: 2;
    li {
      margin-bottom: 20px;
    }
  `;

const Regional = ({ className, heading, subheading, cta, carouselItems }) => {
  const regions = carouselItems.slice(1).map((region, index) => {
    return (
      <li key={index}>
        <Link className="primary p4" to={`${region.url}/`}>
          {get(region, "linkText", "Region")}
        </Link>
      </li>
    );
  });

  return (
    <Container className={`${className || ""}`}>
      <Heading>{heading || "Regional"}</Heading>
      <Subheading>
        {subheading ||
          "We believe in a world that is locally rooted and globally connected."}
      </Subheading>
      <CtaContainer>
        <CtaText className="p4">
          {get(
            carouselItems,
            "[0].title",
            "Want to start an Acumen Fellowship in your country?"
          )}
        </CtaText>
        &nbsp;
        <CtaButton
          className="primary"
          href={"mailto:fellowsinfo@acumenacademy.org"}
        >
          {get(cta, "linkText", "Get in Touch")}
        </CtaButton>
      </CtaContainer>
      <RegionList>{regions}</RegionList>
    </Container>
  );
};

export default Regional;
