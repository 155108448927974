import React from "react";
import PropTypes from "prop-types";

function RightGoToArrow({ fillColor }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="38"
      viewBox="0 0 38 38"
    >
      <g id="Group_122" data-name="Group 122" transform="translate(-1082 -723)">
        <path
          id="Ellipse_39"
          data-name="Ellipse 39"
          d="M19,1A18,18,0,0,0,6.272,31.728,18,18,0,1,0,31.728,6.272,17.882,17.882,0,0,0,19,1m0-1A19,19,0,1,1,0,19,19,19,0,0,1,19,0Z"
          transform="translate(1082 723)"
          fill={fillColor || "#fff"}
        />
        <path
          id="Path_972"
          data-name="Path 972"
          d="M9.625,6.75H.5V5.25H9.625L5.438,1.063,6.5,0l6,6-6,6L5.438,10.938Z"
          transform="translate(1094.5 736)"
          fill={fillColor || "#fff"}
        />
      </g>
    </svg>
  );
}

RightGoToArrow.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fillColor: PropTypes.string,
  className: PropTypes.string,
};

export default RightGoToArrow;
