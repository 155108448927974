import { graphql, useStaticQuery } from "gatsby";

const usePartnersData = () => {
  const data = useStaticQuery(graphql`
    query {
      partnersData: allContentfulPageLayout(
        filter: {
          queryIdentifier: { eq: "Our Partners Page" }
          node_locale: { eq: "en-US" }
        }
      ) {
        nodes {
          heading
          shortDescription
          contentModules {
            ... on ContentfulImage {
              image {
                fluid {
                  aspectRatio
                  srcSet
                }
              }
              imageDescription: description
            }
            ... on ContentfulLink {
              linkText
              url
            }
            ... on ContentfulTitleDescription {
              title
              description {
                description
              }
              link {
                linkText
                url
              }
            }
            ... on ContentfulCarouselContent {
              heading
              subheading
              cta {
                linkText
                url
              }
              carouselItems {
                ... on ContentfulTitleDescription {
                  title
                  link {
                    linkText
                    url
                  }
                }
                ... on ContentfulImage {
                  image {
                    fluid {
                      aspectRatio
                      srcSet
                    }
                  }
                  description
                }
                ... on ContentfulLink {
                  linkText
                  url
                  startDate
                  endDate
                }
                ... on ContentfulNameImageDetails {
                  name
                  photoLogo {
                    fluid {
                      aspectRatio
                      srcSet
                    }
                  }
                  bioAbout {
                    bioAbout
                  }
                  masterClassUrl
                }
              }
            }
          }
          marketingModal {
            ... on ContentfulMarketingModal {
              id
              cta {
                linkText
                url
              }
              description {
                description
              }
              title
              showAfterPercent
              hubspotListId
              marketingModalImage {
                image {
                  fluid {
                    aspectRatio
                    srcSet
                  }
                }
              }
              formFields
              successTitle
              successDescription
              additionalText {
                json
              }
            }
          }
        }
      }
    }
  `);
  return data.partnersData.nodes[0];
};

export default usePartnersData;
